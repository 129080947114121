@tailwind base;
@tailwind components;
@tailwind utilities;
body {
    background-color: #1F2937; /* Or any other color for dark mode */
  }

  input[type="date"]::placeholder,
input[type="time"]::placeholder {
  color: white; /* Replace with the desired color */
  opacity: 1; /* Override browser default which might be causing low visibility */
}